import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * ### Why do we use a custom `cn` function instead of `tailwind-variants`?
 *
 * - **First reason** - mismatch between the interfaces of our `cn` function and
 * the `cn` function from `tailwind-variants`. To me, the `cn` function in
 * `tailwind-variants` currently feels more like an internal function rather
 * than one that can be confidently used outside the library's internal code
 *
 * - **Second reason** - when using `cn` only with `tailwind-variants`, we would
 * need to pass the `tw-merge` config in EVERY call to `tv()`, as this config is
 * cached internally within `tailwind-variants`
 *
 * ### Why do we use `clsx` in this function?
 *
 * While `tailwind-merge` already includes `clsx` internally (more specifically,
 * it literally copies the code from {@link
 * https://github.com/dcastil/tailwind-merge/blob/v2.6.0/src/lib/tw-join.ts|tw-join.ts}),
 * it uses version `clsx 1.2.1`, whereas we use `clsx 2^`
 *
 * @author Ivan Shchedrovskyi
 */
export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs))
}
